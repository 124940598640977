import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './post-6.jpg';
import styles from './post-grid.module.css';

const Post6 = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Dostaňte se do formy díky zdravému stravování | NutritionPro"}
        description={"Konec nesmyslných předsevzetí. Ukážeme vám, jak se dostat do formy díky zdravému stravování. Potřebujete jen motivaci dodržet několik snadných zásad."}
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/dostante-se-do-formy">
          Dostaňte se do formy díky zdravému stravování
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Dostaňte se do formy díky zdravému stravování"
              date="26.03.2022"
            />
            <div>
              <p className={styles.postText}>
                Zimní měsíce, jako je leden a únor, jsou zkouškou nejen naší formy, ale také motivace. Během vánočních svátků si většina z nás uvolní pravidla stravování a pro některé už může být těžší <strong>dostat se zpět do formy</strong>. Výčitky, že už je únor, a stále jste se sebou nezačali nic dělat, vám nepomohou. Vykašlete se na ně, <strong>ukážeme vám, jak začít díky zdravému stravování</strong>.
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Vsaďte na lehčí jídla</h5>
              <p className={styles.postText}>
                Lehčími jídly samozřejmě nemyslíme jen saláty nebo velmi malé porce, které si možná vybavíte z poslední diety. Je rozdíl, pokud budete celý den pracovat v kanceláři, nebo máte manuálně náročnou práci – podle toho byste měli volit <strong>složení jídla</strong> a stejně tak <strong>velikost porcí</strong> (zkrátka počítat kalorie, které přijmete během dne). Lehčí jídlo je dobrým základem, jak se postupně dostat do formy a cítit se lépe. 
              </p>
              <p className={styles.postText}>
                Není snadné se vyznat v tom, co jíst, jaký byste měli mít poměr bílkovin, sacharidů a tuků. Složité je pro laika také určit si správný poměr kalorií, které za den přijmete. <a href="https://nutritionpro.cz/">Zdravé krabičky NutritionPro</a> to <strong>všechno udělají za vás a vyvážené jídlo vám doručí až domů</strong>.
              </p>
              <h5 className={styles.postTitle}>Vneste do svého jídelníčku řád</h5>
              <p className={styles.postText}>
                Bez řádu a pravidelnosti se budete do formy dostávat dlouho. Nejčastějším problémem bývá <strong>vynechávání jídel během dne</strong>. Někdy je to snídaně, jindy oběd – jednoduše proto, že nestíháte. To <strong>ve výsledku vede k přejídání večer</strong>. Proto doporučujeme, udělat si ve stravování řád a dopřát si na jídlo dostatek času. Důležité je nehladovět, potom snadno sáhnete po rychlém a nezdravém řešení.
              </p>
              <h5 className={styles.postTitle}>Nezapomínejte dostatečně pít</h5>
              <p className={styles.postText}>
                Dostatečný pitný režim je důležitý. Jak poznáte, že málo pijete? Projevuje se to především <strong>bolestmi hlavy</strong>, ale také <strong>dehydratovanou pokožkou</strong>. Ideální je pít především <strong>obyčejnou čistou vodu nebo například čaje</strong>. Případně můžete bez omezení pít také kávu. Určitě byste se měli vyhnout různým slazeným limonádám, které sice dobře chutnají, ale jinak jsou spíše škodlivé kvůli vysokému obsahu cukru. Ideálně doporučujeme vypít 30–45 ml vody na 1 kg své váhy. Pokud vážíte například 80 kg, je to 2,4 až 3,6 litru. Samozřejmě záleží na počasí a mnoha dalších faktorech. 
              </p>
              <h5 className={styles.postTitle}>Žádné výčitky, motivujte se</h5>
              <p className={styles.postText}>
                Díky těmto několika tipům se <strong>můžete dostat do formy</strong>. Musíte však vydržet. Proto je důležitá motivace. Měli byste mít jasný důvod, proč to děláte, proč se chcete dostat do formy, přejít na zdravé stravování. Jedině tak dlouhodobě vydržíte. Pokud máte výčitky, že jste například nedodrželi své novoroční předsevzetí, zapomeňte na to. <strong>Dejte si raději reálné cíle</strong>.  
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default Post6
